import axios from "axios";
//const Domain = `${process.env.REACT_APP_API}`;
const Domain = `https://crediap.com`;
const Url = `${Domain}/crediApp/api/`;

export const ApiService = {
  getUsers: () =>
    axios.get(`${Url}users`),
  addBountyData: (user, token) =>
    axios.post(`${Url}bounty/addBountyData`,
    {
      type: localStorage.getItem('type-user'),
      user,
    },
    {
      headers: {
        token,
      },
    }),
  registrarUser: (user) => axios.post(`${Url}users/addUser`, user),
  updateUser: (user, token, typeUser) =>
    axios.put(`${Url}users`, {user, typeUser}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  changePassword: (id, password) => axios.put(`${Url}users/recover`, {id, password}),
  consumePoints: (data) => axios.put(`${Url}users/consumePoints`, data),
  registrarUserGoogle: (user) => axios.post(Url + "addUserGoogle", user),
  registrarUserFacebook: (user) => axios.post(Url + "addUserFaceBook", user),
  registrarUserBussines: (user) =>
    axios.post(`${Url}users/addUserBusiness`, user),
  login: (user) =>
    axios.post(
      `${Url}login/loginUser`,
      {
        email: user.email,
        password: user.password,
        lastConnection: user.lastConnection
      },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    ),
  loginBusiness: (user) =>
    axios.post(
      `${Url}login/loginUserBusiness`,
      { email: user.email, password: user.password, lastConnection: user.lastConnection },
      { headers: { "Content-type": "application/json" } }
    ),
  searchUser: (data) =>
    axios.get(
      `${Url}users/searchUser/${data}`,
    ),
  getUser: (token) =>
    axios.post(
      `${Url}users/getUser`,
      {},
      {
        headers: {
          token,
        },
      }
    ),
  getBusinessUser: (token) =>
    axios.post(
      `${Url}users/getUserBusiness`,
      {},
      {
        headers: {
          token,
        },
      }
    ),
  getCompanies: () =>
      axios.get(`${Url}users/getCompanies`),
  addCompanyUser: (data) =>
    axios.post(`${Url}users/addCompanyUser`, data),
  removeUserFromCompany: (data) =>
    axios.delete(
      `${Url}users/removeUser/${data.companyId}/${data.userId}`
    ),
  removeUser: (id) =>
    axios.delete(`${Url}users/removeUser/${id}`),
  updateCompany: (company, token) =>
    axios.put(`${Url}users/updateUserBusiness/${company.id}`, company, {
      headers: token
    }),
  updateCompanyUser: (user) =>
    axios.put(`${Url}users/updateCompanyUser`, user),
  buyOffersQuotes: (payload) =>
    axios.post(`${Url}offers/buyQuotas`, payload),
  getAllOffers: () =>
    axios.get(`${Url}offers`),
  getOffersByCompany: (id) =>
    axios.get(`${Url}offers/${id}`),
  addOffer: (offer) =>
    axios.post(`${Url}offers/`, offer),
  updateOffer: (id, offer) =>
    axios.put(`${Url}offers/${id}`, offer),
  updateOfferViews: (id, views) =>
    axios.put(`${Url}offers/updateOffersViews/${id}`, views),
  removeOffer: (id) =>
    axios.delete(`${Url}offers/${id}`),
  removeCompany: (id) =>
    axios.delete(`${Url}users/removeCompany/${id}`),
  uploadImage: (img) =>
    axios.post('https://api.cloudinary.com/v1_1/ark-software/image/upload', img, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  /* loginGoogle: (user) =>
    axios.post(Url + "loginUserGoogle", {
      user,
    }),
  loginFacebook: (user) =>
    axios.post(Url + "loginUserFacebook", {
      user,
    }),
  attachUserToCompany: (data) =>
    axios.put(
      `${Url}users/attachUser`,
      {
        email: data.companyEmail,
        userId: data.userId
      }
    ),
  */
};
